import React, { Component } from "react";

class Footer extends Component {
    render()
    {
        return (
            <div id="footer" className="background-blue">
                <div className="uk-text-center background-blue">
                    <div className="uk-container">
                        <p className="white">cemedicament.ro furnizeaza informatii despre peste <a href="/medicamente">5500 de produse</a>. Aceste informatii sunt pentru
                            scop educational/informativ si nu constituie un sfat medical. Date actualizate pe 16 Aprilie
                            2020.</p>
                        <p className="white">Contact: contact @ cemedicament . ro</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer;
